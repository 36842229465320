import React, { memo } from 'react'
import Grid from '@mui/material/Grid'
import Box from '@mui/material/Box'
import { FormattedMessage } from 'react-intl'
import { Container, Typography } from '@mui/material'
// import Link from 'next/link'
import Link from 'next/link'
import sx from './styles'

const Footer = () => {
  // const { formatMessage: f } = useIntl()
  const currentYear = new Date().getFullYear()

  return (
    <Container
      maxWidth={false}
    >
      <Grid
        container
        py={1}
      >
        <Grid
          item
          xs={12}
          lg={12}
        >
          <Box sx={sx.leftText}>
            <Typography
              variant="caption"

            >
              <FormattedMessage
                id="ALL_RIGHT_RESERVED"
                values={{ year: currentYear }}
              />
            </Typography>
            {/* <Typography variant="caption">
              <FormattedMessage id="TERMS_AND_CONDITIONS" />
            </Typography>
            <Typography variant="caption">
              <FormattedMessage id="SITEMAP" />
            </Typography>
           } */}
            <Link
              href="/aviso-de-privacidad"
              passHref
            >
              <Typography
                variant="link-text"
                ml={1}
              >
                <FormattedMessage id="SIMPLIFIED_PRIVACY_TITLE" />
              </Typography>
            </Link>
          </Box>
        </Grid>
        {/* <Grid
          item
          xs={12}
          lg={6}
        >
          <Box sx={sx.rightText}>
            <Typography
              variant="caption"
            >
              <FormattedMessage
                id="ALL_RIGHT_RESERVED"
                values={{ year: currentYear }}
              />
            </Typography>
          </Box>
        </Grid> */}

      </Grid>

      {/* <Box>
        <Link
          href={`${f({ id: 'ROUTE_TERMS_AND_CONDITION' })}`}
          passHref
        >
          <Typography
            variant="link-text"
            component="a"
            href={`${f({ id: 'ROUTE_TERMS_AND_CONDITION' })}`}
            sx={sx.linktTitle}
            title={f({ id: 'TERMS_AND_CONDITIONS' })}
          >
            <FormattedMessage id="TERMS_AND_CONDITIONS" />
          </Typography>
        </Link>
      </Box> */}
    </Container>
  )
}

export default memo(Footer)
