const styles = {
  root: {
    paddingY: 1,
  },

  leftText: {
    // textAlign: { xs: 'center', lg: 'left' },
    textAlign: 'center',
  },

  rightText: {
    textAlign: { xs: 'center', lg: 'right' },
  },

}

export default styles
